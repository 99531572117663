import React, { Component } from 'react';
import PasswordForgetForm from '../../PasswordForget/Form';
import { Link } from 'gatsby';

import style from './PasswordForget.scss';

class PasswordForget extends Component {
  render() {
    return (
      <div className={`container ${style.container}`}>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-6 ${style.content}`}>
            <h1>Forgot your password?</h1>
            <PasswordForgetForm />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className={`col-12 col-md-6 ${style.content}`}>
            <p>
              <Link to="/">Return home</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordForget;
